<template>
  <a-modal
      v-model="showPopup"
      title="扣除保证金"
      :width="800"
  >
    <template #footer>
      <a-button key="back" @click="handleCancel">取消</a-button>
      <a-button key="submit" type="primary" @click="handleConfirm">确定</a-button>
    </template>
    <div class="popup-content">
      <a-form-model
          ref="ruleForm"
          :model="params"
          v-bind="layout"
      >
        <div class="w-100-w font-weight-bold color-red text-align-center mb-20">
          扣除商家保证金，赔偿到买家钱包
        </div>
        <a-form-item label="赔付金额">
          <a-input
              allowClear
              v-model="params.paidMoney"
              class="w-200"
              type="number"
              placeholder='赔付金额'
          ></a-input>
        </a-form-item>
        <a-form-model-item label="保证金扣除描述">
          <a-textarea
              v-model="params.deductDesc"
              placeholder="扣除描述"
              :rows="4"
          />
        </a-form-model-item>
        <a-form-model-item label="收款方描述">
          <a-textarea
              v-model="params.paidDesc"
              placeholder="收款方描述"
              :rows="4"
          />
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-modal>
</template>
<script>
export default {
  data() {
    return {
      showPopup: false,
      orderId: '',
      layout: {
        labelCol: { span: 4 }, //标签占5份  共24份
        wrapperCol: { span: 20 },
      },
      params: {
        orderId: undefined,
        paidMoney: undefined,
        deductDesc: undefined,
        paidDesc: undefined,
      }
    }
  },
  methods: {
    /** 展示弹窗 */
    show(data) {
      this.orderId = data
      this.params.orderId = data
      this.showPopup = true
    },
    /** 取消 */
    handleCancel() {
      this.params = {
        orderId: undefined,
        paidMoney: undefined,
        deductDesc: undefined,
        paidDesc: undefined,
      }
      this.showPopup = false
    },
    /** 确定 */
    async handleConfirm() {
      if (Number(this.params.paidMoney) <= 0) {
        return this.$message.warn('赔偿金额不能为空')
      }
      this.params.paidMoney = Number(this.params.paidMoney)
      this.$loading.show()
      const res = await this.axios.post("/dq_admin/orderProduct/orderDepositPaidAccount", {
        ...this.params
      })
      this.$loading.hide()
      if (res.status != 200) return
      this.$emit('paidOrderPrice')
      this.handleCancel()
      this.showPopup = false
    }
  }
}
</script>