<template>
  <a-modal
      v-model="showModel"
      :width='800'
      title="平台成交总览"
      cancel-text="取消"
      @cancel="cancelPushModal"
  >
    <div class="push-modal">
      <div>
        <a-range-picker
            v-model="times"
            :placeholder="['订单开始日期','订单结束日期']"
            class="w-400"
            @change="handleDownloadChangeTime"
        />
        <a-button
            icon="download"
            class="btn ml-20"
            type="primary"
            :loading="loadingBtn"
            @click="handleDownloadData"
        >{{ btnText }}</a-button>
      </div>
    </div>
    <div slot="footer">
      <a-button @click="cancelPushModal">关闭</a-button>
    </div>
  </a-modal>
</template>

<script>
export default {
  data() {
    return {
      btnText: '统计数据导出',
      loadingBtn: false,
      showModel: false,
      titleName: '平台成交总览',
      times: [],
      params: {
        startDay: undefined,
        endDay: undefined
      }
    };
  },
  methods: {
    /** 导出文件 */
    async handleDownloadData() {
      if (!this.params.startDay && !this.params.endDay) return this.$message.warning('请选择时间')
      this.$loading.show()
      this.loadingBtn = true
      this.btnText = '导出中...'
      const res = await this.axios('/dq_admin/orderDeal/dayReport', {
        params: {
          startDay: this.params.startDay,
          endDay: this.params.endDay
        }
      })
      this.$loading.hide()
      this.loadingBtn = false
      this.btnText = '统计数据导出'
      if (res.status !== '200') return this.$message.warning('导出超时，请再次尝试')
      // return
      // 列标题
      const jsonData = res.data
      let str = "<tr style='text-align:center'>";
      // "日期": "2024-04-14",
      //     "GMV": 0,
      //     "GMS": 0,
      //     "总订单成交率": "0/2=0.00",
      //     "总金额成交率": "0/260=0.00",
      //     "甄选订单支付率": "0/0=0",
      //     "甄选金额支付率": "0/0=0",
      //     "甄选(200下)订单成交率": "0/0=0",
      //     "甄选(200-500)订单成交率": "0/0=0",
      //     "甄选(500-1000)订单成交率": "0/0=0",
      //     "甄选(1000+)订单成交率": "0/0=0",
      //     "精品订单支付率": "0/2=0.00",
      //     "精品金额支付率": "0/260=0.00",
      //     "精品(200下)订单成交率": "0/2=0.00",
      //     "精品(200-500)订单成交率": "0/2=0.00",
      //     "精品(500-1000)订单成交率": "0/2=0.00",
      //     "精品(1000+)订单成交率": "0/2=0.00",
      //     "普品订单支付率": "0/0=0",
      //     "普品金额支付率": "0/0=0",
      //     "普品(200下)订单成交率": "0/0=0",
      //     "普品(200-500)订单成交率": "0/0=0",
      //     "普品(500-1000)订单成交率": "0/0=0",
      //     "普品(1000+)订单成交率": "0/0=0"
      const objKeys = Object.keys(jsonData[0])
      objKeys.forEach(key => {
        str += `<td>${ key }</td>`
      })
      // 循环遍历，每行加入tr标签，每个单元格加td标签
      for (let i = 0; i < jsonData.length; i++) {
        str += `<tr style='text-align:center'>`
        objKeys.forEach(key => {
          str += `<td>${jsonData[i][key] + "\t"}</td>`
        })
      }
      // Worksheet名
      const worksheet = "斗泉";
      const uri = "data:application/vnd.ms-excel;base64,";

      // 导出的表格模板数据
      const template = `<html xmlns:o="urn:schemas-microsoft-com:office:office"
        xmlns:x="urn:schemas-microsoft-com:office:excel"
        xmlns="http://www.w3.org/TR/REC-html40">
        <head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>
        <x:Name>${worksheet}</x:Name>
        <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet>
        </x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->
        </head><body><table>${str}</table></body></html>`;
      // 导出模板
      const dayStr = this.params.startDay + '-' + this.params.endDay
      const fileName = this.titleName + '-' + dayStr
      const href = uri + this.base64(template)
      let link = document.createElement('a');
      link.setAttribute('href', href);
      link.setAttribute('download', `${fileName}.xls`);
      link.click();
    },
    base64(s) {
      return window.btoa(unescape(encodeURIComponent(s)));
    },
    /** 展示弹窗 */
    async show() {
      this.params = {
        startDay: undefined,
        endDay: undefined
      }
      this.titleName = '平台成交总览'
      this.showModel = true
    },
    /** 改变日期 */
    handleDownloadChangeTime(array, arrayStr) {
      if (arrayStr && arrayStr.length > 0) {
        this.params.startDay = arrayStr[0]
        this.params.endDay = arrayStr[1]
      } else {
        this.params.startDay = ""
        this.params.endDay = ""
      }
    },
    // 关闭
    cancelPushModal() {
      this.showModel = false
    },
  },
};
</script>

<style lang="scss" scoped>

</style>